function C() {
  return (
    <div className="content">
      
      <iframe className="badgeTHM" src="https://tryhackme.com/api/v2/badges/public-profile?userPublicId=747549" width={330} height={90}></iframe>
      
      <p className="eyeWindow"></p>
      
      <a className="items" href="https://github.com/alaamjadi" target="_blank" rel="noopener noreferrer">Github</a>
      
      <a className="items" href="https://www.coursera.org/learner/alaamjadi" target="_blank" rel="noopener noreferrer">Coursera</a>

      <a className="items" href="https://www.linkedin.com/in/alaamjadi/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      
      <a className="items" href="https://forms.gle/v6rv4PdM5FYkAoeB8" target="_blank" rel="noopener noreferrer">Contact Me</a>

      <form className="items" action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="5CLK4YNCPPLYW" />
        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        <img alt="" border="0" src="https://www.paypal.com/en_IT/i/scr/pixel.gif" width="1" height="1" />
      </form>

      {/* <p><small>Donate BTC ❤️: bc1qltq5ma3r03ywje93d90lqqhr29dhl7l77lwv6t</small></p>
      
      {/* <footer>
        <p className="items">lvl-4 security</p>
      </footer> */}
      
    </div>
  );
}
export default C;