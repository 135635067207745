import React, { useEffect, useState } from 'react';

function TW() {
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [typingSpeed] = useState(100);
    const fullTexts = ['Mohammad - Ala Amjadi', 'Mohammad - A', 'M - A', 'M-A', 'M-4', 'lvl-4', 'lvl-4.com'];
    
    const findCommonPrefix = (str1, str2) => {
      let i = 0;
      while (i < str1.length && i < str2.length && str1[i] === str2[i]) {
        i++;
      }
      return i;
    };
    
    const getDeleteCount = (currentText, nextText) => {
      const commonPrefixLength = findCommonPrefix(currentText, nextText);
      return currentText.length - commonPrefixLength;
    };
    
    useEffect(() => {
      let timer;
      const currentText = fullTexts[currentTextIndex];
      const nextText = currentTextIndex < fullTexts.length - 1 ? fullTexts[currentTextIndex + 1] : currentText;

      const type = () => {
        if (isDeleting) {
          const deleteCount = getDeleteCount(currentText, nextText);
          if (text.length > currentText.length - deleteCount) {
            setText(prev => prev.slice(0, -1));
          } else {
            setIsDeleting(false);
            if (currentTextIndex < fullTexts.length - 1) {
              setCurrentTextIndex(prevIndex => prevIndex + 1);
            }
          }
        } else {
          if (text.length < currentText.length) {
            setText(currentText.slice(0, text.length + 1));
          } else if (currentTextIndex < fullTexts.length - 1) {
            setTimeout(() => setIsDeleting(true), 1700);
          }
        }
      };
      timer = setTimeout(type, isDeleting ? 50 : typingSpeed);
      return () => clearTimeout(timer);
    }, [text, isDeleting, currentTextIndex, fullTexts, typingSpeed]);
    return (
      <div className='top'>
        <div className="typewriter">
          <h1 className='a'>{text}</h1>
        </div>
      </div>
    );
  }
export default TW;