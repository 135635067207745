import './App.css';
import './index.css';

import './Components/Video/VB.css';
import VB from './Components/Video/VB';

import './Components/TypeWriter/TW.css'
import TW from './Components/TypeWriter/TW'

import './Components/Content/C.css'
import C from './Components/Content/C' 

function App() {
  return (
    <div className="App">
      <VB/>
      <TW/>
      <C/>
    </div>
  );
}

export default App;