import React from 'react';
import './VB.css';

const VB = () => (
  <div className="video-container" aria-hidden="true">
    <video
      autoPlay
      muted
      loop
      playsInline
      className="background-video">
      <source src="/media/watching.mp4" type="video/mp4"/>
    </video>
  </div>
);

export default VB;